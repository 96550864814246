import { useState } from "react";
import { NavHashLink } from "@xzar90/react-router-hash-link";
import { BrowserRouter } from "react-router-dom";

import LogoSVG from "./assets/images/logo.svg";
import footerLogo from "./assets/images/footer-logo.png";

import landingBg from "./assets/images/landing-bg.png";
import topBg from "./assets/images/top-bg.png";
import bottomBg from "./assets/images/bottom-bg.png";

import styles from "./App.module.scss";

const points = [
  {
    title: "Actionable tasks",
    content:
      "Increase manager engagement by automatically creating actionable tasks and task trackers to monitor completion.",
  },
  {
    title: "Full account management",
    content:
      "Customers can view and manage their unit activities, history, and payments smoothly.",
  },
  {
    title: "Tenant 360° profile",
    content:
      "Gives managers a full overview of their customers' performance and profitability. ",
  },
  {
    title: "Advanced customer website",
    content:
      "SEO-optimized website to maximize user engagement and conversion rate.",
  },
  {
    title: "Multiple location management",
    content:
      "Optimized for multiple location management to benefit and support efficiency and tracking.",
  },
  {
    title: "Complete hand free reservations",
    content:
      "Enable customers to complete the entire booking process online from reservation to move-in, including digital document signing.",
  },
  {
    title: "Leading-edge reporting engine",
    content:
      "Set up and save advanced reports, automatically send reports to managers/stakeholders.",
  },
  // {
  //   title: "Real-life site map",
  //   content:
  //     "Allow customers to choose a unit from a tangible interactive site map to help them visualize and select their desired unit.",
  // },
  {
    title: "Advanced mobile management",
    content:
      "Complete mobile platform to enable managers to handle their tasks on the go/field.",
  },
  {
    title: "Advanced Self Move-out",
    content:
      "Enables customers to easily move out online without requiring any interactions with the store manager.",
  },
  {
    title: "Seamless transition",
    content:
      "Enables customers to seamlessly import their customer and unit information from their existing software.",
  },
];

function App() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // const [openBanner, setOpenBanner] = useState(false);

  const postMail = () => {
    fetch(
      "https://emailsenderprod.azurewebsites.net/api/emailSenderProduction",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fromEmail: "admin@shmili.com",
          toEmail: "shmilibreuer@gmail.com",
          subject: "Email contact from slfstrg.com",
          data: [
            { label: "Name", value: name },
            { label: "Email", value: email },
            { label: "Business Name", value: businessName },
            { label: "Phone", value: phone },
            { label: "Message", value: message },
          ],
        }),
      }
    )
      .then((response) => response)
      .then((data) => {
        setName("");
        setBusinessName("");
        setEmail("");
        setEmail("");
        setMessage("");
        // console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className={styles.app}>
      <BrowserRouter>
        <header className={styles.header}>
          <div className={`df aic ${styles.headerContent}`}>
            <LogoSVG />

            <div className={`mla df aic m-r-20 ${styles.navBtns}`}>
              <NavHashLink
                to="/#features"
                className={`df acc ffmsb fs18 ${styles.featuresButton}`}
              >
                Features
              </NavHashLink>
              <NavHashLink
                to="/#contact"
                className={`df acc ffmsb fs18 ${styles.fullButton}`}
              >
                Contact Us
              </NavHashLink>
            </div>
          </div>
        </header>

        <section className={styles.landing}>
          <div className={`df aic ${styles.landingContent}`}>
            <div className={styles.leftSec}>
              <h1 className={`ffmsb fs50`}>
                The most innovative self storage management software
              </h1>
              <div className={`m-b-40 ${styles.margin}`}></div>
              <h4 className={`ffmr fs18`}>
                Slfstrg Software system offers an innovative and efficient
                management solution for self-storage owners to increase
                efficiency, boost rental conversion, and maximize profits.
              </h4>

              <div className={`df m-t-80 ${styles.btnsWrpr}`}>
                {/* <button className={`ffmsb fs18 m-r-30 ${styles.contact}`}>
                  Contact Us
                </button> */}

                <NavHashLink
                  to="/#contact"
                  className={`df acc ffmsb fs18 m-r-30 ${styles.contact}`}
                >
                  Contact Us
                </NavHashLink>
                <NavHashLink
                  to="/#features"
                  className={`df acc ffmsb fs18 m-r-30 ${styles.learn}`}
                >
                  Learn More
                </NavHashLink>
              </div>
            </div>
          </div>
          <img className={styles.landingImage} src={landingBg} alt="" />
        </section>

        <div className={`p-t-60 p-b-60 ${styles.topBanner}`}>
          <h3 className={`tac ffmr fs14 m-b-20`}>
            Select your limited time offer
          </h3>
          <div className={`df aic ${styles.bannerContent}`}>
            <div className={`df ffc aic tac ffmr fs14 ${styles.bannerSec}`}>
              <h5 className={`ffmsb fs16`}>
                6 Months full money back guaranty
              </h5>
              <div className={`m-t-20 ${styles.offerDetails} ${styles.open}`}>
                <p>
                  Sign up and start using our software for your facility and we
                  are sure you will love it. In case you are not fully satisfied
                  with our software we will give you full refund for your money.
                  Offer valid for 6 months from first staring to use our
                  software.
                </p>
              </div>
              <NavHashLink
                to="/#contact"
                className={`ffmsb fs14 m-t-25 ${styles.gsButton}`}
              >
                Get started
              </NavHashLink>
            </div>

            <div className={`df ffc aic tac ffmr fs14 ${styles.bannerSec}`}>
              <h5 className={`ffmsb fs16`}>Get first 3 months totally free</h5>
              <div className={`m-t-20 ${styles.offerDetails} ${styles.open}`}>
                <p>
                  Sign up and start using our software for your self storage
                  facility and pay nothing for the first three months, you won't
                  pay anything for the first three months including onboarding
                  fees, monthly fees and services fees, it is fully free.
                </p>
              </div>
              <NavHashLink
                to="/#contact"
                className={`ffmsb fs14 m-t-25 ${styles.gsButton}`}
              >
                Get started
              </NavHashLink>
            </div>
          </div>

          {/* <div
            onClick={() => setOpenBanner(!openBanner)}
            className={`${styles.showMore} ${openBanner ? styles.open : ""}`}
          ></div> */}
        </div>

        <section className={`${styles.features}`} id="features">
          <img className={styles.topBg} src={topBg} alt="" />
          <img className={styles.bottomBg} src={bottomBg} alt="" />
          <div className={`${styles.featuresContent}`}>
            <h2 className={`ffmsb fs35 m-t-80 m-b-60`}>
              Competitive Advantages
            </h2>

            <div className={styles.featuresList}>
              {points.map((point) => (
                <div className={`df aic p-r-50 ${styles.feature}`}>
                  <div>
                    <h5 className={`ffmm fs14`}>{point.title}</h5>
                    <p className={`ffmr fs12 ${styles.featureContent}`}>
                      {point.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className={styles.contact} id="contact">
          <div className={`${styles.contactContent}`}>
            <h3 className={`ffmsb fs35 tac`}>Contact Us</h3>
            <div className={`${styles.contactForm}`}>
              <div className={`m-b-20 ${styles.formLine}`}>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{ width: "calc(50% - 10px)" }}
                  className={`ffmr fs16`}
                  placeholder="Name"
                />
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ width: "calc(50% - 10px)" }}
                  className={`ffmr fs16`}
                  placeholder="Phone"
                />
              </div>

              <div className={`df ${styles.formLine}`}>
                <div
                  style={{ width: "calc(50% - 10px)" }}
                  className={styles.secondLine}
                >
                  <div style={{ width: "100%" }}>
                    <input
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                      style={{ width: "100%" }}
                      className={`ffmr fs16 m-b-20`}
                      placeholder="Business Name"
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ width: "100%" }}
                      className={`ffmr fs16`}
                      placeholder="Email *"
                    />
                  </div>
                </div>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className={`ffmr fs16`}
                  placeholder="Message"
                  style={{
                    width: "calc(50% - 10px)",
                    marginLeft: "20px",
                    padding: "18px 24px",
                  }}
                ></textarea>
              </div>
              <div className={`m-t-20 df`}>
                <button
                  disabled={!email.length}
                  onClick={postMail}
                  className={`mla ${styles.sendButton}`}
                >
                  SEND MESSAGE
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className={`${styles.footer}`}>
          <div className={`df ${styles.footerContent}`}>
            <div>
              <p className={`ffmsb fs14`}>Email us</p>
              <a
                className={`ffmr fs14 ${styles.emailLink}`}
                href="mailto:admin@slfstrg.com"
              >
                admin@slfstrg.com
              </a>
            </div>

            <div className={`mla`}>
              <img src={footerLogo} alt="" />
            </div>
          </div>
        </section>
      </BrowserRouter>
    </div>
  );
}

export default App;
